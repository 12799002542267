import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from '../form/lookPic/index';
import addForm from '../form/add/index';

export default {
  extends: TablePage,
  components: {
    Form,
    addForm,
  },
  name: 'inventory',
  data() {
    return {

    };
  },
  created() {
    this.getConfigList('page_list');
  },

  methods: {
    setColumn(col) {
      const rowData = col;
      if (col.field === 'pictureRespVoList') {
        rowData.type = 'html';
        rowData.formatter = ({ row }) => '<span style="color:#C34231">查看</span>';
      }
      return col;
    },
    cellClick({ row, column }) {
      if (column.property === 'pictureRespVoList') {
        this.formName = 'Form';
        this.formConfig = {
          type: 'view',
          id: row.id,
        };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
    // 禁用
    modalClick({ val, row }) {
      const urls = '';
      const text = '';
      if (val.code === 'disable') {
        if (!this.selection || this.selection.length === 0) {
          return this.$message.error('请至少选择一条数据');
        }
        request.post('/sfa/sfaStockCheck/disableCheck', this.selection).then((res) => {
          console.log(res);
          if (res.success) {
            this.$message.success(res.message);
            this.getList();
          }
        });
      }
      if (val.code === 'add') {
        this.formName = 'addForm';
        this.modalConfig.title = '新增';
        this.formConfig = {
          code: val.code,
        };
        this.openFull();
      }
      if (val.code === 'edit') {
        this.formName = 'addForm';
        this.modalConfig.title = '编辑';
        this.formConfig = {
          code: val.code,
          row,
        };
        this.openFull();
      }
    },
  },
};
