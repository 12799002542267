var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-select" },
    [
      _c(
        "div",
        {
          staticClass: "cost-select el-textarea el-input--small",
          staticStyle: { "min-height": "32px" },
          on: { click: _vm.openModal },
        },
        _vm._l(_vm.value, function (item, index) {
          return _c(
            "el-tag",
            {
              key: index,
              attrs: { closable: "" },
              on: {
                close: function ($event) {
                  return _vm.handleClose(item)
                },
              },
            },
            [_vm._v(_vm._s(item.customerName || "请选择客户"))]
          )
        }),
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }