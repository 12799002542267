<template>
    <div>
        <div class="pic_title">照片</div>
        <div class="pic_list">
           <img class="img" :src="item.pictureUrl" v-for="(item,index) in value" :key="index"/>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  created() {
  },
};
</script>

<style lang="less" scoped>
    .pic_title{
        width: 100%;
        font-size: 20px;
        color: #333;
        font-weight: bolder;
    }
    .pic_list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .img{
            width: 200px;
            height:100px;
            margin: 10px;
        }
    }
</style>
