import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import lookPic from './index.vue';

formCreate.component('lookPic', lookPic);
export default {
  extends: Form,
  data() {
    return {
      formFunctionCode: 'picForm',
      buttons: {
        submit: false,
        close: true,
        loading: false,
        submitAudit: false,
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  created() {

  },

  methods: {
    formComplete() {
      request.post('/sfa/sfaStockCheck/getStockCheckTaskDetailPicture', this.formConfig.id).then((res) => {
        if (res.success) {
          console.log(res.result);
          this.setValue({ pic: res.result });
        }
      });
    },
  },
};
